import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from "gatsby";
import { FiInfo } from "react-icons/fi";
import Img from "gatsby-image";
import Title from "../components/Title";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1> My first MDX file m8</h1>
    <h2>Introduction</h2>
    <mdxImage>![Graphiql](https://res.cloudinary.com/dg9q8ex5v/image/upload/v1596826522/Graphi_QL_Screenshot_8179ed5232.png)
    </mdxImage>
    <p>
  "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
  praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias
  excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui
  officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum
  quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum
  soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime
  placeat facere possimus, omnis voluptas assumenda est, omnis dolor
  repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum
  necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae
  non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut
  reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus
  asperiores repellat."
    </p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`hello
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-Java"
      }}>{`private int read;

Class Pint() {
  Pint(){

  }

}
`}</code></pre>
    <h3> nice text </h3>
    <p>
  At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
  praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias
  excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui
  officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum
  quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum
  soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime
  placeat facere possimus, omnis voluptas assumenda est, omnis dolor
  repellendus. <Link to="/about" mdxType="Link">Temporibus</Link> autem quibusdam et aut
  officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates
  repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a
  sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur
  aut perferendis doloribus asperiores repellat."
    </p>
    <h3>React Components</h3>
    <p>
  "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
  praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias
  excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui
  officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum
  quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum
  soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime
  placeat facere possimus, omnis voluptas assumenda est, omnis dolor
  repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum
  necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae
  non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut
  reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus
  asperiores repellat."
    </p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from "react"

const Counter = () => {
  const [count, setCount] = React.useState(0)
  return (
    <section className="counter">
      <h4>show some love to MDX</h4>
      <h4>likes {count}</h4>
      <button className="btn btn-danger" onClick={() => setCount(count + 1)}>
        i like mdx
      </button>
    </section>
  )
}
export default Counter
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      